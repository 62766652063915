import React, { useState, useEffect } from "react";
import MakerLogSEO from "components/MakerLog/MakerLogSEO";

import {
  Container,
  PostInputContainer,
  Title,
  Discord
} from "components/MakerLog/styles";
import Spinner from "react-spinkit";

import Posts from "components/MakerLog/Posts";
import styled from "styled-components";

import { Row, Col } from "react-grid-system";

import { getMakerLogPosts, fetchUserDataByUid } from "api/db";
import PostInput from "components/MakerLog/PostInput";
import TopMakers from "components/MakerLog/TopMakers";
import { BrowserView } from "react-device-detect";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const makers = () => {
  const [posts, setPosts] = useState([]);
  const [streakData, setStreakData] = useState([]);

  useEffect(() => {
    getMakerLogPosts(snapshot => {
      let data = snapshot.val();
      let key = snapshot.key;
      fetchUserDataByUid(data.uid).once("value", function(snapshot) {
        let userData = snapshot.val();
        setStreakData(oldStreakData => oldStreakData.concat(userData));
        let keyObject = { key: key };
        const object = { ...data, ...userData, ...keyObject };
        setPosts(users => users.concat(object));
      });
    });
  }, []);

  return (
    <>
      <MakerLogSEO
        group="Community"
        heading="Community"
        description="Daily updates from the community!"
        label="Daily updates from the community"
      />
      <Container>
        {posts.length > 1 ? (
          <Row>
            <Col lg={3}>
              <BrowserView>
                <h1 className="text-2xl tracking-tight leading-none font-bold text-gray-800 mt-4 text-center">
                  Community
                </h1>
              </BrowserView>
              <div className="text-center">
                <div className="-space-x-4 mt-4 ">
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/erica_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/erin_headshot.png"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/varun_headshot1.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/nisreen_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/nori_headshot.png"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/liv_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/oliver_headshot.jpg"
                    alt="Profile image"
                  />
                </div>
                <p className="mx-auto max-w-lg mt-3 text-2xl text-gray-700">
                  Join our Discord community.
                </p>
                <span className="relative inline-flex mt-3">
                  <span className="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    100+ online now
                  </span>
                  <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                  </span>
                </span>
                <br />
                <div className="rounded-md shadow-sm relative inline-flex mt-3">
                  <a target="_blank" href="https://discord.gg/8FwTpfN">
                    <button className="inline-flex items-center px-4 py-2 border border-gray-100 text-base leading-6 font-medium rounded-md text-gray-800 bg-white hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                      Enter chat
                    </button>
                  </a>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <PostInputContainer>
                <PostInput />
              </PostInputContainer>
              <Posts posts={posts} />
            </Col>
            <Col lg={3}>
              <h1 className="text-2xl tracking-tight leading-none font-bold text-gray-800 mt-4 text-center">
                Gallery
              </h1>

              <a target="_blank" href="/gallery">
                <div className="bg-gray-200 rounded-md p-4 m-4 mb-8">
                  <h1 className="text-gray-900 text-xl font-bold tracking-tight">
                    Check out hundreds of project submissions on the Enlight
                    gallery ›
                  </h1>
                </div>
              </a>

              <h1 className="text-xl tracking-tight leading-none font-bold text-gray-800 mt-4 text-center">
                Top Makers
              </h1>
              <TopMakers streakData={streakData} />
            </Col>
          </Row>
        ) : (
          <SpinnerContainer>
            <Spinner name="ball-scale-multiple" color="#438cee" />
          </SpinnerContainer>
        )}
      </Container>
    </>
  );
};

export default makers;
