import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 90%;
`;

export const PostInputContainer = styled.div`
  max-width: 40em;
  margin: auto;
`;

export const Discord = styled.a`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  padding: 24px;
  background: #fefefe;
  -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  color: #333;
`;

export const Title = styled.h3`
  text-align: center;
  margin: 32px 0px;
`;

export const Card = styled.div`
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  background: #fefefe;
  -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  transition: all 200ms ease;
  display: flex;
  flex-direction: column;
  color: #333;

  .postButton {
    text-align: right;
  }

  section {
    word-break: break-word;
  }

  .reactions {
    display: flex;
  }

  .reactions div {
    margin-right: 4px;
  }

  .reaction {
    background: #f4fbff;
    border: 2px solid #f4fbff;
    color: #2f80ed;
    cursor: pointer;
    margin: 0;
    padding: 6px;
    display: inline-block;
    border-radius: 4px;
    transition: all 300ms ease;
  }

  .liked {
    border: 2px solid #2f80ed;
  }

  section img {
    max-height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-top: 16px;
  }

 

  p {
    font-size: 18px;
  }

  &:active,
  &:focus,
  &:hover {
    -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }

  #profile {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    position: relative;
    color: #333;
    div small {
      font-weight: normal;
      position: absolute;
      top: 0;
      right: 0;
      color: #767676;
    }
    div span {
      font-size: 12px;
    }
    div span span {
      background: #f4fbff;
      color: #2f80ed;
      display: inline-block;
      margin: 0;
      padding: 4px;
      border-radius: 8px;
    }
    div span span span {
      padding: 2px;
    }
  }

  #maker {
    display: flex;
    flex-direction: row;
    position: relative;
    color: #333;
    font-size: 12px;
    div p {
      font-size: 14px;
    }
    div span {
      font-size: 12px;
    }
    div span span {
      background: #f4fbff;
      color: #2f80ed;
      font-weight: normal;
      display: inline-block;
      padding: 4px;
      border-radius: 8px;
    }
  }

  @media (max-width: 768px) {
    margin: 15px 0px;
  }

  main .built-this {
  }

  span .source {
    height: 100px;
    width: 50%;
    display: inline;
    padding: 8px;
    border: 2px solid #f5af19;
    margin-right: 8px;
    color: #333;
    border-radius: 4px;
    transition: all 300ms ease;
    font-weight: bold;

    &:hover {
      color: #fff;
      background: #f5af19;
    }
  }

  span .demo {
    height: 100px;
    width: 50%;
    display: inline;
    padding: 8px;
    border: 2px solid #0394fc;
    margin-right: 8px;
    color: #333;
    border-radius: 4px;
    transition: all 300ms ease;
    font-weight: bold;

    &:hover {
      color: #fff;
      background: #0394fc;
    }
  }
`;

export const ProfilePic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: auto 0;
  margin-right: 10px;
  -moz-box-shadow: 0 0 10px #e4e4e4;
  -webkit-box-shadow: 0 0 10px #e4e4e4;
  box-shadow: 0 0 10px #e4e4e4;
`;

export const InputField = styled(TextareaAutosize)`
  resize: none;
  height: 150px;
  font-size: 18px;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 100%;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const ImageUpload = styled.input`
  font-size: 14px;
  padding: 8px;
  border-radius: 3px;
  color: #474747;
  display: inline-block;
  box-sizing: border-box;
  &:focus {
    border: 2px solid #438cee;
  }
`;

export const PostButton = styled.button`
  display: inline;
  width: 80px;
  padding: 8px;
  cursor: pointer;
  border: 4px solid #0394fc;
  margin-right: 8px;
  color: #333;
  border-radius: 4px;
  transition: all 300ms ease;
  font-weight: bold;
  background: #fff;

  &:hover {
    cursor: pointer;
    color: #fff;
    background: #0394fc;
  }
`;

export const Badge = styled.h4`
  background: #f4fbff;
  color: #2f80ed;
  display: inline-block;
  font-size: 12px;
  padding: 8px;
  border-radius: 8px;
  margin-left: 4px;
`;
