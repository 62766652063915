import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Card, ProfilePic } from "components/MakerLog/styles";

export default function TopMakers({ streakData }) {
  const [topStreaks, setTopStreaks] = useState(null);

  useEffect(() => {
    let streakDataSet = Array.from(new Set(streakData.map(a => a.id))).map(
      id => {
        return streakData.find(a => a.id === id);
      }
    );

    setTopStreaks(streakDataSet);
  }, [streakData]);

  return (
    <div>
      {topStreaks &&
        topStreaks
          .sort((a, b) => {
            return b.streak - a.streak;
          })
          .map((streak, index) => (
              <Card key={`${streak.id}${index}`}>
                <Link to={`/user/${streak.id}`} id="maker">
                  <ProfilePic src={streak.photoURL} />
                  <div>
                    <p>{streak.username}</p>
                    <span>
                      @{streak.id}{" "}
                      <span>
                        🔥<span>{streak.streak}</span>
                      </span>
                    </span>
                  </div>
                </Link>
              </Card>
          ))}
    </div>
  );
}
