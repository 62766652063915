import React from "react";
import Helmet from "react-helmet";

import TitleContainer from "components/Elements/TitleContainer";
import Label from "components/Elements/Label";

const MakerLogSEO = ({ heading, label, description }) => {
  return (
    <div>
      <Helmet
        title={`${heading} | Enlight`}
        meta={[
          {
            name: "description",
            content: `${description}`
          },
          {
            name: "og:title",
            content: `${heading} | Enlight`
          },
          {
            name: "og:description",
            content: `${description}`
          },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/maker-log-social.png"
          },
          {
            name: "twitter:title",
            content: `${heading} | Enlight`
          },
          {
            name: "twitter:description",
            content: `${description}`
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/maker-log-social.png"
          }
        ]}
      />

      <div className="p-4 mx-auto max-w-xl text-center">
        <h1 className="text-4xl tracking-tight leading-none font-extrabold text-gray-800 mt-4">
          {heading}
        </h1>
        <p className="mt-4 text-xl uppercase tracking-widest text-gray-700">
          {label}
        </p>
      </div>
    </div>
  );
};

export default MakerLogSEO;
